@import '../../styles/variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    margin: 0;
  }
}

.content {
  background-color: $winter;
  border-radius: $space-tiny;
  max-width: 375px;
  width: 100%;
  overflow: hidden;
  padding-bottom: $space-xs;
  display: flex;
  flex-direction: column;
  background-image: url('../../../public/logo512.png');
  background-position: 150%;
  background-size: contain;
  background-repeat: no-repeat;
}

.title {
  background-color: $error;
  padding: $space-xxs $space-s;
  text-transform: uppercase;
  color: $winter;
  @include text-caption($font-caption-1, $font-bold)
}

.message {
  min-height: $space-xxm;
  padding: $space-m $space-xs;
  display: flex;
  align-items: center;
  width: 100%;
}

.action {
  width: 50%;
  align-self: center;
  display: grid;
}
