@use 'sass:list';
@import '../../node_modules/include-media/dist/_include-media.scss';

/*
  Layout
 */
$max-width-page: 1444px;
$max-width-section: 1120px;
$max-width-content: 940px;

/*
  Spacings
 */

$space-xlg: 128px;
$space-lg: 92px;
$space-xxl: 64px;
$space-xl: 56px;
$space-l: 48px;
$space-xxm: 36px;
$space-xm: 28px;
$space-m: 24px;
$space-s: 18px;
$space-xs: 14px;
$space-xxs: 12px;
$space-caption: 10px;
$space-tiny: 8px;

/*
  Radius
 */

$radius-l: 22px;
$radius-m: 12px;
$radius-s: 8px;
$radius-tiny: 5px;

/*
  Colors palette and Themes
 */

$default-theme: 'oceans';

@function get_theme($theme: $default-theme) {
  @if ($theme == 'oceans') {

    @return [#14279B #3D56B2 #5C7AEA #FFFFFF];
  } @else if ($theme == 'pastel'){

    @return [#6D8299 #316B83 #8CA1A5 #D5BFBF];
  } @else if ($theme == 'neon') {

    @return [#0E49B5 #153E90 #54E346 #FFFAA4]
  }
}

$dark: list.nth(get_theme('oceans'), 1);
$main: list.nth(get_theme('oceans'), 2);
$light: list.nth(get_theme('oceans'), 3);
$winter: list.nth(get_theme('oceans'), 4);
$bright-gray: #ECF0F3;
$light-grey: #F9FAFC;
$dark-grey: #737474;
$black-shadow: #C4B6B6;
$error: #C70039;
$overlay: rgba(196, 182, 182, 0.35);
/*
  Fonts
 */
// Family
$web-font: 'Quicksand', sans-serif;
// Sizes
$font-heading-1: 28px;
$font-heading-2: 24px;
$font-heading-3: 20px;
$font-heading-4: 18px;
$font-heading-5: 16px;
$font-caption-1: 14px;
$font-caption-2: 12px;
$font-caption-3: 10px;

// Weights
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;

// Line-height
$line-height-xl: 32px;
$line-height-l: 24px;
$line-height-m: 18px;

/*
  Mixins
 */

@mixin heading($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin heading-1 {
  @include heading($font-heading-1, $font-bold);
}

@mixin heading-2 {
  @include heading($font-heading-2, $font-semi-bold);
}

@mixin heading-5 {
  @include heading($font-heading-5, $font-bold);
}

@mixin text($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin text-caption($font-size: 10px, $font-weight: $font-regular) {
  @include text($font-size, $font-weight);
}

@mixin text-body($font-size: 16px, $font-weight: $font-light) {
  @include text($font-size, $font-weight);
}

/*
  Media query
 */

$breakpoints: (phone: 589px, tablet: 792px, desktop: 1024px);

@mixin for-desktop {
  @include media(">=desktop") {
    @content
  }
}

@mixin for-tablet-landscape {
  @include media(">=tablet", "<desktop") {
    @content
  }
}

@mixin for-tablet-portrait {
  @include media(">=phone", "<tablet") {
    @content
  }
}

@mixin for-phone {
  @include media("<=phone") {
    @content
  }
}

/*
  Effects
 */

$box-shadow-s: 1px 1px 8px 1px $black-shadow;
$box-shadow-l: 1px 1px 24px 2px $black-shadow;
$box-shadow-light: 1px 1px 5px 1px $light;
$box-shadow-winter: 1px 1px 8px 1px $winter;

$text-shadow-s: 1px 1px $black-shadow;

$cubic-bezier-flash: 0, 1, 1, -2;
$cubic-bezier-ease: 0.5, 0, 0.5, 2;
$cubic-bezier-linear: 0.1, 0.3, 0.6, 0.7;

$transition-duration-fast: 300ms;
$transition-duration-slow: 600ms;
