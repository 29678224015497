@import '../../styles/variables';

.container {
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;
  padding: 0 4px;
  border-radius: $radius-s;
  gap: $space-xs;

  & label {
    @include text-caption($font-caption-1, $font-regular);
    cursor: pointer;
  }

  &:hover input {
    transform: translateX($space-tiny);
  }

  & input {
    margin: 0;
    transition: transform $transition-duration-slow cubic-bezier($cubic-bezier-ease);
    color: $light;
    background-color: $dark;

    &:checked {
      transform: translateX($space-tiny);
    }
  }
}
