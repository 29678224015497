@import '../../styles/variables';

.container {
  width: 90%;
  max-width: $max-width-content;
  margin: $space-xs auto;
  text-align: justify;
  line-height: $line-height-l;

  @include for-desktop {
    width: 75%;
  }
}
