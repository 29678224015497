@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include for-tablet-landscape {
    width: 20%;
  }

  @include for-desktop {
    width: 20%;
  }
}

.label {
  @include heading-5;
  padding-bottom: $space-tiny;
  border-bottom: 2px solid $winter;
  color: $winter;
}

.text {
  @include text-body($font-caption-1);
  color: $winter;
  font-weight: $font-semi-bold;
  line-height: $line-height-xl;
}
