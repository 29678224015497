.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-image: url('../../../public/layout-background.jpeg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
