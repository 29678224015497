@import '../../styles/variables';

.form {
  display: flex;
  flex-direction: column;
  margin: $space-m 0;
}

.message {
  @include text-body($font-caption-1, $font-light);
  color: $dark;
}

.reLogin {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: $space-m;
}

.actions {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr max-content;
  margin-top: $space-m;
  gap: $space-xxs;
}
