@import '../../styles/variables';

.container {
  min-width: $space-xl;
  max-width: 2 * $space-xlg;
  padding: $space-xs $space-s;
  border: none;
  border-radius: $radius-l;
  cursor: pointer;
  font-size: $font-caption-2;
  font-weight: $font-semi-bold;
  text-transform: uppercase;

  transition: box-shadow $transition-duration-fast cubic-bezier($cubic-bezier-linear);

  &:hover {
    box-shadow: $box-shadow-winter;
  }
}

.primary {
  background-color: $light;
  color: $winter;
}

.inverted {
  background-color: $bright-gray;
  color: $dark-grey;
}

.disabled {
  background-color: transparent;
  border: 1px solid $dark-grey;
  color: $dark-grey;
  cursor: default;
}
