@import '../../styles/variables';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $space-xl;
  background-color: $dark;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: $max-width-page;
  padding: 0 $space-tiny;
  overflow: hidden;

  @include for-desktop {
    @include text-body($font-caption-1);
    height: 100%;
    padding: 0 $space-m;
  }
}

.logo {
  height: 52px;
  margin-left: $space-xxs;

  @include for-desktop {
    margin-left: 0;
  }

  & .image {
    height: 100%;
  }
}

.links {
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  align-items: center;
  margin: 0 $space-xxs;
  color: $light;
  font-size: $font-caption-2;
  font-weight: $font-medium;
  transition: color $transition-duration-slow cubic-bezier($cubic-bezier-flash);

  @include for-desktop {
    padding: $space-tiny 0;
    font-size: $font-heading-5;
  }

  &:hover {
    color: $winter;
  }

  & .label {
    display: none;
    @include for-desktop {
      display: block;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  font-size: $font-heading-2;

  @include for-desktop {
    display: none;
    font-size: $font-heading-2;
  }
}

.homeIcon {
  display: flex;
}

.activeLink {
  color: $winter;
}
