@import '../../styles/variables';

.image {
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
  background-color: $winter;
  box-shadow: $box-shadow-winter;
  display: flex;
  justify-self: center;
}

.small {
  width: $space-xxm;
  height: $space-xxm;
}

.medium {
  width: $space-l;
  height: $space-l;
}

.large {
  width: $space-xlg;
  height: $space-xlg;
}
