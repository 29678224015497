@import '../../styles/variables';

.container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 30%);
  gap: $space-m;

  @include for-phone {
    grid-template-columns: 1fr;
  }
}

.picture {
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: $space-m;

  & .section {
    display: flex;
    flex-direction: column;
    align-items: center;

    & p:first-child {
      font-weight: $font-semi-bold;
    }
  }

  & .text {
    margin: 0;
  }
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;

  & .heading {
    font-size: $font-heading-3;
    font-weight: $font-bold;
  }

  & .group {
    display: flex;
    justify-content: space-between;

    & .label {
      font-size: $font-caption-1;
      font-weight: $font-semi-bold;
      padding-left: $space-m;
    }

    & .data {
      @include text-caption($font-caption-1)
    }
  }
}
