@import '../../styles/variables';

.form {
  display: flex;
  flex-direction: column;
  margin: $space-m 0;

  & .staffPhone {
    height: $space-xl;
    opacity: 1;
    transition: all $transition-duration-fast ease;

    &.hide {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
}

.regTypes {
  margin: $space-s 0;
  border-radius: $radius-m;

  @include for-desktop {
    padding: 0 $space-s $space-s;
    border: 1px solid $winter;
    box-shadow: $box-shadow-winter;
  }
}

.caption {
  @include text-caption($font-heading-5, $font-semi-bold)
}

.radios {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, minmax(max-content, 42%));
  justify-content: space-between;
  gap: $space-m;

  & .radio {
    display: grid;
    align-items: center;
    grid-template-columns: auto min-content;
    padding: 0 4px;
    border-radius: $radius-s;
    gap: $space-tiny;

    & label {
      @include text-caption($font-caption-1, $font-regular);
      cursor: pointer;
    }

    &:hover input {
      transform: translateX(-$space-xxs);
    }

    & input {
      margin: 0;
      transition: transform $transition-duration-slow cubic-bezier($cubic-bezier-ease);

      &:checked {
        transform: translateX(-$space-xxs);
      }
    }
  }
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  margin-top: $space-xs;
  gap: $space-xxs;
}
