@import '../../styles/variables';

.container {
  display: grid;
}

.socials {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, $space-xm);
  width: 100%;
  gap: $space-xxs;

  .logInPill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow $transition-duration-fast cubic-bezier($cubic-bezier-ease);

    &:hover {
      box-shadow: $box-shadow-winter;
    }

    .image {
      width: $space-xm;
      height: $space-xm;
    }
  }
}

.caption {
  @include text-caption($font-caption-1, $font-light);
}
