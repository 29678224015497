@import '../../styles/variables';

.container {
  width: 90%;
  max-width: $max-width-content;
  margin: $space-xs auto;
  text-align: justify;
  line-height: $line-height-l;

  @include for-desktop {
    width: 75%;
  }
}

.heading {
  @include heading-1;
  text-align: center;
}

.separator {
  box-shadow: $box-shadow-light;
  border: none;
}

.text {
  @include text-body($font-heading-4, $font-regular);

  &.highlight {
    color: $main;
    text-align: center;
    font-size: $font-heading-3;
    font-weight: $font-bold;
  }
}

.thanks {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: $space-xxl auto;
}
