@import '../../styles/variables';

.form {
  display: flex;
  flex-direction: column;
  margin: $space-m 0;

  & .staffPhone {
    height: $space-xl;
    opacity: 1;
    transition: all $transition-duration-fast ease;

    &.hide {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
}

.link {
  @include text-caption($font-heading-5, $font-semi-bold);
  color: $main;
}

.heading {
  @include heading-5;
}

.action {
  display: grid;
  margin: $space-m 0;
}
