@import '../../styles/variables';

.container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: $space-xm 1fr;
  padding: $space-tiny $space-s;
  gap: 8px;

  &:hover {
    box-shadow: 1px 0 5px -3px $dark-grey;
  }

  & .icon {
    color: $light;
    align-self: center;
    font-size: $font-heading-2;

  }

  & .error {
    color: $error;
  }

  & .field {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    & input {
      padding: $space-tiny 0;
      margin-top: $space-xxs;
      background-color: transparent;
      border: none;
      outline: none;
      color: $light;
      font-size: $font-heading-5;

      &[type='password'] {
        font-weight: $font-bold;
      }

      &:focus ~ label, &:not(:placeholder-shown) ~ label {
        color: $main;
        font-size: $font-caption-2;
        font-weight: $font-semi-bold;
        transform: translateY(-$space-m);
      }

      &:not(:placeholder-shown) ~ .eye {
        display: block;
      }
    }

    & label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include text-caption($font-heading-5);
      color: $dark-grey;
      transition: all $transition-duration-fast cubic-bezier($cubic-bezier-ease);
    }

    & .eye {
      position: absolute;
      display: none;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
    }

    & .errorText {
      @include text-caption($font-caption-2);
      position: absolute;
      bottom: -$space-s;
      right: 0;
      color: $error;
    }
  }

  &.topRadius {
    border-radius: $space-tiny $space-tiny 0 0;
  }

  &.bottomRadius {
    border-radius: 0 0 $space-tiny $space-tiny;
  }

  &.noneRadius {
    border-radius: unset;
  }

  &.light {
    background-color: $winter;
  }

  &.dark {
    background-color: $bright-gray;
  }

  &.radius {
    border-radius: $space-tiny;
  }
}
