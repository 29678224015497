@import '../../styles/variables';

$padding-horizontal: 24px;

.container {
  display: flex;
  justify-content: center;
  background-color: $main;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: $max-width-page;
  min-height: 335px;
  flex-flow: column wrap;
}

.heading {
  display: flex;
  flex-grow: 1;
  width: 100%;
  padding: $padding-horizontal;
  flex-flow: row wrap;
  gap: $space-xm;

  & .brand {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    & .logo {
      width: 180px;
      height: 180px;
    }
  }

}

.copyright {
  display: flex;
  align-items: center;
  min-height: $space-xxl;
  padding: 0 $padding-horizontal;
  background-color: $dark;
  border-top: 1px solid $light;
  color: $winter;
  @include text-caption($font-caption-2, $font-regular);
  border-top-right-radius: $font-heading-3;
  border-top-left-radius: $font-heading-3;
}
