@import './styles/variables';

.routes {
  display: flex;
  flex-grow: 1;
  padding: $space-l 0;
  background-color: transparent;

  @include for-desktop {
    padding: 48px 0;
  }
}
