@import '../../styles/variables';

.form {
  display: flex;
  flex-direction: column;
  margin: $space-xs 0;
}

.forgotPassword {
  @include text-caption($font-caption-1, $font-semi-bold);
  align-self: flex-end;
  margin: $space-xxs 0;
  cursor: pointer;
  color: $light;

  &:hover {
    text-shadow: $text-shadow-s;
  }
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  gap: $space-xxs;
}
