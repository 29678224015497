@import '../../styles/variables';

.container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  border-radius: $space-tiny;
  overflow: hidden;

  @include for-desktop {
    width: 90%;
    max-width: $max-width-section;
    padding: $space-l 0;
    box-shadow: $box-shadow-winter;
  }
}

.abstract {
  display: flex;
  align-items: center;
  width: auto;
  max-width: 480px;
  padding: $space-m 0;

  @include for-phone {
    display: none;
  }

  @include for-tablet-portrait {
    display: none;
  }
}

.absImage {
  width: auto;
  height: 85%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 375px;
  padding: $space-tiny;

  @include for-phone {
    width: 90%;
  }
}

.heading {
  @include heading-1;
}

.caption {
  @include text-caption($font-caption-1, $font-light);
}
